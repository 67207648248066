import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ERequestsNavigationTab } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { requestsForSellerNavigationTabs } from 'entities/Requests/Requests.const';
import { IncomingRequestsForSeller } from 'entities/Requests/components/IncomingRequestsForSeller';
import { SupplyListForSeller } from 'entities/Supply/components/SupplyListForSeller';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ xBasketId, setBasket, getBasketById }) => {
  const { tab } = useParams();

  const header = <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>;
  const navigation = <NeedListNavigation items={requestsForSellerNavigationTabs} />;

  useEffect(() => {
    if (xBasketId) {
      getBasketById(xBasketId);
    } else {
      setBasket(null);
    }
  }, [xBasketId]);

  if (tab === ERequestsNavigationTab.SupplyList) {
    return <SupplyListForSeller header={header} navigation={navigation} />;
  }

  return <IncomingRequestsForSeller header={header} navigation={navigation} />;
};

const mapState = (state: RootState) => ({
  xBasketId: state.authState.xBasketId,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setBasket: dispatch.basketState.setBasket,
  getBasketById: dispatch.basketState.getBasketById,
});

export const RequestsForSeller = connect(mapState, mapDispatch)(Component);
