import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ERequestsNavigationTab } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { requestsForUserNavigationTabs } from 'entities/Requests/Requests.const';
import { CreatedNeedListForUser } from 'entities/Need/components/CreatedNeedListForUser';
import { CancelledNeedListForUser } from 'entities/Need/components/CancelledNeedListForUser';
import { SupplyListForUser } from 'entities/Supply/components/SupplyListForUser';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ auth, getBasketById }) => {
  const { tab } = useParams();

  const basketId = auth?.access.basketId;
  const header = <div className="text-body color-dark-grey need-list__subtitle">Мои заявки</div>;
  const navigation = <NeedListNavigation items={requestsForUserNavigationTabs} />;

  useEffect(() => {
    if (basketId) {
      getBasketById(basketId);
    }
  }, [basketId]);

  if (tab === ERequestsNavigationTab.Cancelled) {
    return <CancelledNeedListForUser header={header} navigation={navigation} />;
  }

  if (tab === ERequestsNavigationTab.SupplyList) {
    return <SupplyListForUser header={header} navigation={navigation} />;
  }

  return <CreatedNeedListForUser header={header} navigation={navigation} />;
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasketById: dispatch.basketState.getBasketById,
});

export const RequestsForUser = connect(mapState, mapDispatch)(Component);
