import dayjs from 'dayjs';
import { DEFAULT_DEBOUNCE_DELAY, INTEGER_PATTERN } from 'common/config';
import { EColor } from 'common/const/enum';
import { IGroupedSupplyListItem, IOption } from 'common/models';
import { ISupply } from 'entities/Supply/Supply.models';
import { getUserName } from 'entities/Users/Users.helper';

export const toDataSourceMapper = <T>(data?: T[] | null): T[] | undefined =>
  data?.map((item, index) => ({ key: index, ...item }));

export const getId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const debounce = <A = unknown, R = void>(func: (args: A) => R) => {
  let timeoutId: NodeJS.Timeout;

  return (args: A) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(args);
    }, DEFAULT_DEBOUNCE_DELAY);
  };
};

export const getUniqOptions = (options: IOption[]) => {
  return options.reduce((acc: IOption[], currentItem) => {
    if (!acc.some((item) => item.value === currentItem.value)) {
      acc.push(currentItem);
    }

    return acc;
  }, []);
};

export const getGoodsItemStatusColor = (status?: string) => {
  if (status === 'pending') {
    return EColor.Orange;
  }

  return EColor.Gray;
};

export const stringToNumber = (string: string) => Number(string);

export const numberToString = (number: number) => number.toString();

export const isInteger = (number: number) => INTEGER_PATTERN.test(number.toString());

export const mapToMenuWithDivider = <T>(list: T[]): T[] => {
  return list.reduce<T[]>((acc, curr, index) => {
    if (index > 0) {
      acc.push({ type: 'divider' } as T);
    }

    acc.push(curr);

    return acc;
  }, []);
};

export const getCounterWidth = (count: number, responsive?: boolean) => {
  // 11 - estimated letter width in pixels, 80 - sum of button widths and horizontal padding
  return responsive ? `${Math.min(count.toString().length * 11 + 80, 200)}px` : '100%';
};

export const generateUid = () => Math.random().toString(16).slice(2);

export const groupList = {
  byCreatedAt: <T extends { createdAt: string }>(list: T[]) => {
    return list.reduce((acc: { createdAt: string; dataSource: T[] }[], item) => {
      const { createdAt } = item;
      const existingGroup = acc.find((group) => dayjs(createdAt).isSame(group.createdAt, 'day'));

      if (existingGroup) {
        existingGroup.dataSource.push(item);
      } else {
        acc.push({
          createdAt,
          dataSource: [item],
        });
      }

      return acc;
    }, []);
  },
  byUpdatedAt: <T extends { updatedAt: string }>(list: T[]) => {
    return list.reduce((acc: { updatedAt: string; dataSource: T[] }[], item) => {
      const { updatedAt } = item;
      const existingGroup = acc.find((group) => dayjs(updatedAt).isSame(group.updatedAt, 'day'));

      if (existingGroup) {
        existingGroup.dataSource.push(item);
      } else {
        acc.push({
          updatedAt,
          dataSource: [item],
        });
      }

      return acc;
    }, []);
  },
  byAddressLegalUser: <
    T extends {
      deliveryDate?: string | null;
      updatedAt?: string;
      supplies: ISupply[];
    },
  >(
    list: T[],
  ): IGroupedSupplyListItem[] => {
    return list.map((group) => {
      const groupedSupplies = group.supplies.reduce((acc: any, supply) => {
        const { address, legalName, user, account, subdivisionName } = supply;
        const groupKey = `${address?.name}|${legalName}|${getUserName(user.firstName, user.lastName)}`;

        if (!acc[groupKey]) {
          acc[groupKey] = {
            address,
            legalName,
            user,
            account,
            subdivisionName,
            dataSource: [],
          };
        }

        acc[groupKey].dataSource.push(supply);

        return acc;
      }, []);

      return {
        deliveryDate: group.deliveryDate,
        updatedAt: group.updatedAt,
        dataSource: Object.values(groupedSupplies),
      };
    });
  },
};
