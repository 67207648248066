/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useDebounce } from 'common/hooks/useDebounce';
import { EPresetType, ERoute, EScrollDirection } from 'common/const/enum';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootState } from 'app/store';
import { getSearchParamsUrlFromPropertyList, setCatalogGoodsListFilterItemsHidden } from 'entities/Catalog/Catalog.helper';
import { ICatalogGoodsListFilterProperty } from 'entities/Property/Property.models';

export interface ICatalogContext {
  page: number;
  scrollUp: boolean;
  fetchLoading: boolean;
  propertiesForFilter: ICatalogGoodsListFilterProperty[];
  setPage: (value: number) => void;
  setScrollDirection: (value: EScrollDirection) => void;
  setFetchLoading: (value: boolean) => void;
  setFilterIsChanged: (value: boolean) => void;
  setPropertiesForFilter: (value: ICatalogGoodsListFilterProperty[]) => void;
  onCatalogChange: (id: number) => void;
  onCategoryChange: (id: number) => void;
}

export const CatalogContext = createContext<ICatalogContext>({
  page: DEFAULT_PAGINATION_PAGE,
  scrollUp: false,
  fetchLoading: true,
  propertiesForFilter: [],
  setPage: () => {},
  setScrollDirection: () => {},
  setFetchLoading: () => {},
  setFilterIsChanged: () => {},
  setPropertiesForFilter: () => {},
  onCatalogChange: () => {},
  onCategoryChange: () => {},
});

export const useCatalogContext = () => useContext(CatalogContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const CatalogProviderComponent: FC<AllType> = ({ children, propertyList }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [scrollDirection, setScrollDirection] = useState<EScrollDirection>(EScrollDirection.DOWN);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [filterIsChanged, setFilterIsChanged] = useState<boolean>(false);
  const [propertiesForFilter, setPropertiesForFilter] = useState<ICatalogGoodsListFilterProperty[]>([]);
  const { catalogId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getSearchParam } = useSearchParamsHook();

  const scrollUp = scrollDirection === EScrollDirection.UP;

  const onCatalogChange = (id: number) => {
    navigate(`${ERoute.Catalog}/${id}`);
  };

  const onCategoryChange = (id: number) => {
    if (catalogId) {
      navigate(`${ERoute.Catalog}/${catalogId}/${id}`);
    }
  };

  useEffect(() => {
    const propertiesForView = propertyList
      .filter(({ presetType }) => presetType !== EPresetType.NotApplicable)
      .map((property) => {
        const result = getSearchParam(property.id.toString());

        if (result) {
          return { ...property, result: JSON.parse(result) };
        }

        return { ...property, result: [] };
      });

    setPropertiesForFilter(setCatalogGoodsListFilterItemsHidden(propertiesForView));
  }, [location, propertyList]);

  useDebounce(() => {
    if (filterIsChanged) {
      const searchUrl = getSearchParamsUrlFromPropertyList(propertiesForFilter);

      navigate(`${location.pathname}?${searchUrl}`);
      setFilterIsChanged(false);
    }
  }, [filterIsChanged, propertiesForFilter]);

  return (
    <CatalogContext.Provider
      value={{
        page,
        scrollUp,
        fetchLoading,
        propertiesForFilter,
        setPage,
        setScrollDirection,
        setFetchLoading,
        setFilterIsChanged,
        setPropertiesForFilter,
        onCatalogChange,
        onCategoryChange,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  propertyList: state.propertyListState.data,
});

export const CatalogProvider = connect(mapState)(CatalogProviderComponent);
