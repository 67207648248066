import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { EGroupedSupplyListKey, ESearchParams, ESupplyListMenuTab } from 'common/const/enum';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, DEFAULT_LIST_LIMIT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';
import { PendingSupplyListForManager } from 'entities/Supply/components/PendingSupplyListForManager';
import { ProcessingSupplyListForManager } from 'entities/Supply/components/ProcessingSupplyListForManager';
import { CompletedSupplyListForManager } from 'entities/Supply/components/CompletedSupplyListForManager';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { getSupplyListForManagerStatuses } from 'entities/Supply/Supply.helper';
import { supplyListForManagerMenuTabs } from 'entities/Supply/Supply.const';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    subdivisionList,
    userList,
    supplyListLoading,
    groupedSupplyList,
    groupedSupplyListCount,
    groupedSupplyListLoading,
    auth,
    // dispatch
    getSupplySubdivisionListForManager,
    getSupplyUserList,
    setGroupedSupplyList,
    getGroupedSupplyList,
    getGroupedSupplyListPart,
    getUserById,
  } = props;

  const { page, setPage, fetchLoading, setFetchLoading, setSelectedSupplyList } = useSupplyContext();
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const userId = auth?.access.userId;
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);

  const [payload, setPayload] = useState<IGroupedSupplyListPayload>({
    statuses: getSupplyListForManagerStatuses(tab),
    isCancelledBySeller: tab === ESupplyListMenuTab.Completed ? true : undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
    key: tab === ESupplyListMenuTab.Completed ? EGroupedSupplyListKey.ByUpdatedAt : EGroupedSupplyListKey.ByDeliveryDate,
  });

  const onCurrentMenuTabChange = async (key: ESupplyListMenuTab) => {
    const newPayload = {
      ...payload,
      subdivisionIds: undefined,
      userId: undefined,
      search: undefined,
      statuses: getSupplyListForManagerStatuses(key),
      isCancelledBySeller: key === ESupplyListMenuTab.Completed ? true : undefined,
      key: key === ESupplyListMenuTab.Completed ? EGroupedSupplyListKey.ByUpdatedAt : EGroupedSupplyListKey.ByDeliveryDate,
    };

    setFetchLoading(true);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    setSearchParam(ESearchParams.Tab, key);
    setPayload(newPayload);
    await getSupplySubdivisionListForManager({ statuses: newPayload.statuses });
    await getSupplyUserList({ statuses: newPayload.statuses });
    await getGroupedSupplyList(newPayload);
    setFetchLoading(false);
  };

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    await getSupplyUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
    await getGroupedSupplyList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    getGroupedSupplyList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    getGroupedSupplyList(payload);
  };

  const renderContent = () => {
    switch (tab) {
      case ESupplyListMenuTab.Processing: {
        return (
          <ProcessingSupplyListForManager
            list={groupedSupplyList}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
          />
        );
      }
      case ESupplyListMenuTab.Completed: {
        return (
          <CompletedSupplyListForManager
            list={groupedSupplyList}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
          />
        );
      }
      default: {
        return (
          <PendingSupplyListForManager
            list={groupedSupplyList}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
            count={groupedSupplyListCount}
            payload={payload}
            onPayloadChange={setPayload}
          />
        );
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      setGroupedSupplyList([]);

      if (userId) {
        await getUserById(userId);
      }

      await getSupplySubdivisionListForManager({ statuses: payload.statuses });
      await getSupplyUserList({ statuses: payload.statuses });
      await getGroupedSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <InfiniteScrollContainer
      page={page}
      scrollToTopTrigger={[payload]}
      canLoad={!groupedSupplyListLoading && groupedSupplyList.length < groupedSupplyListCount}
      onPageChange={setPage}
      callback={async (page) => {
        await getGroupedSupplyListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
      }}
    >
      {header}

      {navigation}

      <Menu
        items={supplyListForManagerMenuTabs}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Pending : tab]}
        onClick={({ key }) => onCurrentMenuTabChange(key as ESupplyListMenuTab)}
        mode="horizontal"
      />

      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
      </div>

      {renderContent()}
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.supplyListState.subdivisionList,
  userList: state.supplyListState.userList,
  supplyListLoading: state.supplyListState.loading,
  groupedSupplyList: state.groupedSupplyListState.data,
  groupedSupplyListCount: state.groupedSupplyListState.count,
  groupedSupplyListLoading: state.groupedSupplyListState.loading,
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplySubdivisionListForManager: dispatch.supplyListState.getSupplySubdivisionListForManager,
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  getGroupedSupplyListPart: dispatch.groupedSupplyListState.getGroupedSupplyListPart,
  getUserById: dispatch.userState.getUserById,
});

export const SupplyListForManager = connect(mapState, mapDispatch)(Component);
