import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useNavigate } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ENeedCardMenuItem, ENeedStatus, ERequestsMenuTab, ERoute } from 'common/const/enum';
import { COLLECTION_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { getSelectedNeedListTotalAmount, renderNeedListRecords } from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { GoodsCard } from 'entities/Goods/components/GoodsCard-r';

interface IComponentProps {
  list: INeed[];
  loading: boolean;
  count: number;
  payload: INeedListPayload;
  onPayloadChange: (value: INeedListPayload) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreatedNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    // props
    list,
    loading,
    count,
    payload,
    onPayloadChange,
    // state
    statisticsRequestsCount = 0,
    // dispatch
    filterNeedList,
    getNeedList,
    getNeedById,
    updateStatisticsRequestsCount,
    changeNeedStatus,
  } = props;

  const [selectedNeedListCount, setSelectedNeedListCount] = useState<number>(0);
  const [selectedNeedListTotalAmount, setSelectedNeedListTotalAmount] = useState<number>(0);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    setPage,
    setNeedCardCurrentMenuTab,
    openNeedCard,
    setOpenNeedCard,
    checkAll,
    indeterminate,
    selectedNeedListIds,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(list);

      setSelectedNeedList(list);
      setSelectedNeedListCount(list.length);
      setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = list.find((item) => item.id === id) as INeed;
    const newSelectedNeedList = checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id);
    const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(newSelectedNeedList);

    setSelectedNeedList(newSelectedNeedList);
    setSelectedNeedListCount(newSelectedNeedList.length);
    setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredNeedList = list.filter((need) => !ids.includes(need.id));
    const filteredSelectedNeedList = selectedNeedList.filter((need) => !ids.includes(need.id));

    filterNeedList(ids);
    setSelectedNeedList(filteredSelectedNeedList);
    updateStatisticsRequestsCount(statisticsRequestsCount - ids.length);

    if (openNeedCard) {
      setSelectedNeedListCount(filteredSelectedNeedList.length);
      setSelectedNeedListTotalAmount(getSelectedNeedListTotalAmount(filteredSelectedNeedList));
    }

    if (filteredNeedList.length <= COLLECTION_LIMIT_5 && count > filteredNeedList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      onPayloadChange(newPayload);
      getNeedList(newPayload);
    }
  };

  const onAcceptToWorkClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Pending,
      onSuccess: () => {
        setOpenSuccessDrawer(true);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        setOpenWarningDrawer(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) отменены.`);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  return (
    <>
      {!!list.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать заявки
          </Checkbox>

          {!!selectedNeedList.length && (
            <>
              <Button className="button-s primary" onClick={onAcceptToWorkClick}>
                {`Взять в работу: ${priceFormatter(selectedNeedListTotalAmount)}`}
              </Button>

              <Button className="button-s danger" onClick={() => setOpenWarningDrawer(true)}>
                Отклонить выбранные
              </Button>
            </>
          )}
        </div>
      )}

      <Table
        className={`
            table-hover need-list__table
            ${!!list.length && list.length === count && 'need-list__table-end-of-list'}
            ${!list.length && 'need-list__table-empty'}
          `}
        dataSource={list.map((need) => ({ ...need, key: need.id }))}
        columns={renderNeedListRecords(ERequestsMenuTab.Created)}
        rowSelection={{
          selectedRowKeys: selectedNeedListIds,
          onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
          columnWidth: 40,
        }}
        onRow={({ id }) => ({
          onClick: () => {
            getNeedById({
              id,
              onSuccess: () => {
                setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                setOpenNeedCard(true);
              },
            });
          },
        })}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: <NeedListEmpty open={!loading && !list.length} /> }}
        loading={{ spinning: loading, indicator: <SpinIndicator /> }}
      />

      <NeedCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <GoodsCard />

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content={`Вы взяли в работу ${selectedNeedListCount} новых заявок на общую сумму ${priceFormatter(
          selectedNeedListTotalAmount,
        )}`}
        btnTitle="К заявкам в работе"
        onClose={() => setOpenSuccessDrawer(false)}
        onConfirm={() => navigate(`${ERoute.RequestsForManager}?tab=${ERequestsMenuTab.Pending}`)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Завершить все"
        loading={loading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  statisticsRequestsCount: state.statisticsState.data.requestsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterNeedList: dispatch.needListState.filterNeedList,
  getNeedList: dispatch.needListState.getNeedList,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedById: dispatch.needState.getNeedById,
  updateStatisticsRequestsCount: dispatch.statisticsState.updateStatisticsRequestsCount,
});

export const CreatedNeedListForManager = connect(mapState, mapDispatch)(CreatedNeedListForManagerComponent);
