import React, { FC } from 'react';
import { Spin } from 'antd';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { Image } from 'common/components/Image';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { IGoodsShort } from 'entities/Goods/Goods.models';

interface IComponentProps {
  goods: IGoodsShort;
  canBeOpened: boolean;
  loading: boolean;
  onClick: () => void;
}

export const GoodsCardMin: FC<IComponentProps> = ({ goods, canBeOpened, loading, onClick }) => {
  return (
    <Spin spinning={loading} indicator={<SpinIndicator />}>
      <div
        className="goods-card-min"
        style={{ pointerEvents: goods.isAvailableForCustomer && canBeOpened ? 'auto' : 'none' }}
        onClick={onClick}
      >
        <Image size={80} src={goods.image} />

        <div style={{ flex: 1 }}>
          <div className="text-h4 goods-card-min__name" title={goods.name}>
            {goods.name}
          </div>

          <div className="goods-card-min__container">
            <span className="text-accent">{priceFormatter(goods.price)}</span>

            {goods.sellerCode && (
              <div className="text-tag color-dark-grey" style={{ marginBottom: 8 }}>
                {goods.sellerCode}
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};
