import React, { FC } from 'react';
import { Button, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EDateFormat, ESupplyListMenuTab } from 'common/const/enum';
import { groupList } from 'common/helpers/data.helper';
import { RootDispatch } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { GroupedSupplyListStateDataItemType } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { getNeedFormattedDate } from 'entities/Need/Need.helper';
import { GoodsCard } from 'entities/Goods/components/GoodsCard-r';

interface IComponentProps {
  list: GroupedSupplyListStateDataItemType[];
  loading: boolean;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = ({ list, loading, getSupplyById, getNeedById }) => {
  const { setOpenSupplyCard } = useSupplyContext();

  const groupedSupplyList = groupList.byAddressLegalUser(list);

  return (
    <>
      <Spin wrapperClassName="need-list__spin" spinning={loading} indicator={<SpinIndicator />}>
        {groupedSupplyList.map((group, index) => {
          return (
            <div key={index} className="need-list__group">
              <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.updatedAt)}</span>

              <Table
                className="need-list__table need-list__table-expandable"
                rowClassName="need-list__table-expandable-row"
                columns={renderGroupedSupplyListRecords()}
                dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
                expandable={{
                  expandIconColumnIndex: 7,
                  columnWidth: 40,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return (
                      <Button
                        className="button-icon need-list__table-item-expand-btn"
                        icon={expanded ? <ArrowUpShortIcon /> : <ArrowDownShortIcon />}
                        onClick={(e) => onExpand(record, e)}
                      />
                    );
                  },
                  expandedRowRender: ({ dataSource }) => {
                    return (
                      <Table
                        className="table-hover need-list__table"
                        columns={renderSupplyListRecords(ESupplyListMenuTab.Completed)}
                        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                        pagination={false}
                        showHeader={false}
                        onRow={({ id, supplyNeedId }) => ({
                          onClick: async () => {
                            await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                            await getNeedById({ id: supplyNeedId });
                          },
                        })}
                      />
                    );
                  },
                }}
                pagination={false}
                showHeader={false}
              />
            </div>
          );
        })}

        <SupplyListEmpty open={!loading && !groupedSupplyList.length} forSeller isCompleted />
      </Spin>

      <SupplyCard />

      <NeedCard nested />

      <GoodsCard />
    </>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const CompletedSupplyListForSeller = connect(null, mapDispatch)(Component);
