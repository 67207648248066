import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { EGroupedSupplyListKey } from 'common/const/enum';
import { IRootModel } from 'app/store';
import {
  IGroupedSupplyListPayload,
  IGroupedSupplyListState,
  ISupply,
  ISupplyAddressListForSellerPayload,
  ISupplyChangeStatusPayload,
  ISupplyCorrectionPayload,
  ISupplyCreatePayload,
  ISupplyDeletePayload,
  ISupplyLegalListForSellerPayload,
  ISupplyListPayload,
  ISupplyListState,
  ISupplyListUpdateDeliveryDatePayload,
  ISupplyParams,
  ISupplyState,
  ISupplySubdivisionListForManagerParams,
  ISupplySubdivisionListForSellerPayload,
  ISupplyUpdatePayload,
  ISupplyUserListForSellerPayload,
  ISupplyUserListParams,
} from 'entities/Supply/Supply.models';
import { supplyTransport } from 'entities/Supply/Supply.transport';
import { updateGroupedSupplyListState, updateSupplyListState } from 'entities/Supply/Supply.helper';

export const supplyListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
    userList: [],
    subdivisionList: [],
    legalList: [],
    addressList: [],
  } as ISupplyListState,
  reducers: {
    setSupplyList: updateSupplyListState.setSupplyList,
    setSupplyListPart: updateSupplyListState.setSupplyListPart,
    setSupplyListCount: updateSupplyListState.setSupplyListCount,
    setSupplyListLoading: updateSupplyListState.setSupplyListLoading,
    setSupplyListUserList: updateSupplyListState.setSupplyListUserList,
    setSupplyListSubdivisionList: updateSupplyListState.setSupplyListSubdivisionList,
    setSupplyListLegalList: updateSupplyListState.setSupplyListLegalList,
    setSupplyListAddressList: updateSupplyListState.setSupplyListAddressList,
    updateSupplyList: updateSupplyListState.updateSupplyList,
    filterSupplyList: updateSupplyListState.filterSupplyList,
    addSupply: updateSupplyListState.addSupply,
    deleteSupply: updateSupplyListState.deleteSupply,
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getSupplyList(payload: ISupplyListPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyList(payload);
        dispatch.supplyListState.setSupplyList(response.data);
        dispatch.supplyListState.setSupplyListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplyListPart(payload: ISupplyListPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyList(payload);
        dispatch.supplyListState.setSupplyListPart(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async updateSupplyListDeliveryDate(payload: ISupplyListUpdateDeliveryDatePayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.updateSupplyListDeliveryDate(payload);
        dispatch.supplyListState.updateSupplyList(response.data);
        dispatch.supplyListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async changeSupplyListStatus(payload: ISupplyChangeStatusPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.changeSupplyStatus(payload);
        dispatch.supplyListState.updateSupplyList(response.data);
        dispatch.supplyListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplyUserList(params: ISupplyUserListParams) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyUserList(params);
        dispatch.supplyListState.setSupplyListUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplySubdivisionListForManager(params: ISupplySubdivisionListForManagerParams) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplySubdivisionListForManager(params);
        dispatch.supplyListState.setSupplyListSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplySubdivisionListForSeller(payload: ISupplySubdivisionListForSellerPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplySubdivisionListForSeller(payload);
        dispatch.supplyListState.setSupplyListSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplyLegalListForSeller(payload: ISupplyLegalListForSellerPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyLegalListForSeller(payload);
        dispatch.supplyListState.setSupplyListLegalList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplyAddressListForSeller(payload: ISupplyAddressListForSellerPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyAddressListForSeller(payload);
        dispatch.supplyListState.setSupplyListAddressList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
    async getSupplyUserListForSeller(payload: ISupplyUserListForSellerPayload) {
      dispatch.supplyListState.setSupplyListLoading(true);

      try {
        const response = await supplyTransport.getSupplyUserListForSeller(payload);
        dispatch.supplyListState.setSupplyListUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyListState.setSupplyListLoading(false);
      }
    },
  }),
});

export const groupedSupplyListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IGroupedSupplyListState,
  reducers: {
    setGroupedSupplyList: updateGroupedSupplyListState.setGroupedSupplyList,
    setGroupedSupplyListPart: updateGroupedSupplyListState.setGroupedSupplyListPart,
    setGroupedSupplyListCount: updateGroupedSupplyListState.setGroupedSupplyListCount,
    setGroupedSupplyListLoading: updateGroupedSupplyListState.setGroupedSupplyListLoading,
    filterGroupedSupplyList: updateGroupedSupplyListState.filterGroupedSupplyList,
    updateGroupedSupplyList: updateGroupedSupplyListState.updateGroupedSupplyList,
    updateDeliveryDate: updateGroupedSupplyListState.updateDeliveryDate,
    addSupply: updateGroupedSupplyListState.addSupply,
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getGroupedSupplyList({ key, ...rest }: IGroupedSupplyListPayload) {
      dispatch.groupedSupplyListState.setGroupedSupplyListLoading(true);

      try {
        if (key === EGroupedSupplyListKey.ByDeliveryDate) {
          const response = await supplyTransport.getGroupedByDeliveryDateSupplyList({ ...rest });
          dispatch.groupedSupplyListState.setGroupedSupplyList(response.data);
          dispatch.groupedSupplyListState.setGroupedSupplyListCount(response.count);
        }

        if (key === EGroupedSupplyListKey.ByUpdatedAt) {
          const response = await supplyTransport.getGroupedByUpdatedAtSupplyList({ ...rest });
          dispatch.groupedSupplyListState.setGroupedSupplyList(response.data);
          dispatch.groupedSupplyListState.setGroupedSupplyListCount(response.count);
        }
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.groupedSupplyListState.setGroupedSupplyListLoading(false);
      }
    },
    async getGroupedSupplyListPart({ key, ...rest }: IGroupedSupplyListPayload) {
      dispatch.groupedSupplyListState.setGroupedSupplyListLoading(true);

      try {
        if (key === EGroupedSupplyListKey.ByDeliveryDate) {
          const response = await supplyTransport.getGroupedByDeliveryDateSupplyList({ ...rest });
          dispatch.groupedSupplyListState.setGroupedSupplyListPart(response.data);
        }

        if (key === EGroupedSupplyListKey.ByUpdatedAt) {
          const response = await supplyTransport.getGroupedByUpdatedAtSupplyList({ ...rest });
          dispatch.groupedSupplyListState.setGroupedSupplyListPart(response.data);
        }
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.groupedSupplyListState.setGroupedSupplyListLoading(false);
      }
    },
    async changeGroupedSupplyListStatus(payload: ISupplyChangeStatusPayload) {
      dispatch.groupedSupplyListState.setGroupedSupplyListLoading(true);

      try {
        const response = await supplyTransport.changeSupplyStatus(payload);
        dispatch.groupedSupplyListState.updateGroupedSupplyList(response.data);
        dispatch.groupedSupplyListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.groupedSupplyListState.setGroupedSupplyListLoading(false);
      }
    },
    async updateGropedSupplyListDeliveryDate(payload: ISupplyListUpdateDeliveryDatePayload) {
      dispatch.groupedSupplyListState.setGroupedSupplyListLoading(true);

      try {
        const response = await supplyTransport.updateSupplyListDeliveryDate(payload);
        dispatch.groupedSupplyListState.updateDeliveryDate(response.data);
        dispatch.groupedSupplyListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.groupedSupplyListState.setGroupedSupplyListLoading(false);
      }
    },
  }),
});

export const supplyState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ISupplyState,
  reducers: {
    setSupply: (state, payload: ISupply | null) => ({ ...state, data: payload }),
    setSupplyLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getSupplyById(params: ISupplyParams) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.getSupplyById(params.id);
        dispatch.supplyState.setSupply(response);
        dispatch.supplyState.onSuccess(params);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
    async createSupply(payload: ISupplyCreatePayload) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.createSupply(payload);
        dispatch.groupedSupplyListState.addSupply(response.data[0]);
        dispatch.supplyListState.addSupply(response.data[0]);
        dispatch.supplyState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
    async updateSupply(payload: ISupplyUpdatePayload) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.updateSupply(payload);
        dispatch.groupedSupplyListState.updateDeliveryDate([response]);
        dispatch.supplyListState.updateSupplyList([response]);
        dispatch.supplyState.setSupply(response);
        dispatch.supplyState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
    async deleteSupply(payload: ISupplyDeletePayload) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.deleteSupply(payload);
        dispatch.supplyListState.deleteSupply(response.id);
        dispatch.supplyState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
    async changeSupplyStatus(payload: ISupplyChangeStatusPayload) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.changeSupplyStatus(payload);
        dispatch.supplyState.setSupply(response.data[0]);
        dispatch.supplyListState.updateSupplyList(response.data);
        dispatch.supplyState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
    async sendRequestSupplyCorrection(payload: ISupplyCorrectionPayload) {
      dispatch.supplyState.setSupplyLoading(true);

      try {
        const response = await supplyTransport.sendRequestSupplyCorrection(payload);
        dispatch.supplyState.setSupply(response);
        dispatch.supplyListState.updateSupplyList([response]);
        dispatch.supplyState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.supplyState.setSupplyLoading(false);
      }
    },
  }),
});
