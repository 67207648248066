import React, { FC } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EDateFormat, ESupplyListMenuTab, ESupplyStatus } from 'common/const/enum';
import { groupList } from 'common/helpers/data.helper';
import { COLLECTION_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { getNeedFormattedDate } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { GroupedSupplyListStateDataItemType, IGroupedSupplyListPayload, ISupply } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { GoodsCard } from 'entities/Goods/components/GoodsCard-r';

interface IComponentProps {
  list: GroupedSupplyListStateDataItemType[];
  loading: boolean;
  count: number;
  payload: IGroupedSupplyListPayload;
  onPayloadChange: (value: IGroupedSupplyListPayload) => void;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    list,
    loading,
    count,
    payload,
    onPayloadChange,
    // dispatch
    filterGroupedSupplyList,
    getGroupedSupplyList,
    changeGroupedSupplyListStatus,
    getSupplyById,
    getNeedById,
  } = props;

  const { setPage, setOpenSupplyCard, selectedSupplyList, setSelectedSupplyList } = useSupplyContext();

  const selectedSupplyListIds = selectedSupplyList.map((need) => need.id);
  const supplyList = list.flatMap((item) => item.supplies);
  const checkAll = supplyList.length === selectedSupplyList.length;
  const indeterminate = selectedSupplyList.length > 0 && selectedSupplyList.length < supplyList.length;
  const groupedSupplyList = groupList.byAddressLegalUser(list);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (indeterminate) {
      setSelectedSupplyList([]);
    } else {
      setSelectedSupplyList(e.target.checked ? supplyList.filter(({ address }) => !!address) : []);
    }
  };

  const onSupplySelectionChange = (supply: ISupply, checked: boolean) => {
    const newSelectedSupplyList = checked
      ? [...selectedSupplyList, supply]
      : selectedSupplyList.filter((supplyItem) => supplyItem.id !== supply.id);

    setSelectedSupplyList(newSelectedSupplyList);
  };

  const onGroupSelectionChange = (checked: boolean, groupSupplyList: ISupply[]) => {
    const groupSupplyListIds = groupSupplyList.map((supply: ISupply) => supply.id);
    const selectedGroupSupplyList = groupSupplyList.filter((supply: ISupply) => selectedSupplyListIds.includes(supply.id));
    const indeterminate = selectedGroupSupplyList.length > 0 && selectedGroupSupplyList.length < groupSupplyList.length;

    if (checked) {
      setSelectedSupplyList([...selectedSupplyList, ...groupSupplyList]);
    }

    if (!checked) {
      setSelectedSupplyList(selectedSupplyList.filter((supply) => !groupSupplyListIds.includes(supply.id)));
    }

    if (indeterminate) {
      setSelectedSupplyList([
        ...selectedSupplyList,
        ...groupSupplyList.filter((supply: ISupply) => !selectedGroupSupplyList.includes(supply)),
      ]);
    }
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredGroupedSupplyList = list
      .map((group) => {
        return {
          ...group,
          supplies: group.supplies.filter((supply) => !ids.includes(supply.id)),
        };
      })
      .filter((group) => !!group.supplies.length);
    const filteredSelectedSupplyList = selectedSupplyList.filter((supply) => !ids.includes(supply.id));

    filterGroupedSupplyList(ids);
    setSelectedSupplyList(filteredSelectedSupplyList);

    if (filteredGroupedSupplyList.length <= COLLECTION_LIMIT_5 && count > filteredGroupedSupplyList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      onPayloadChange(newPayload);
      getGroupedSupplyList(newPayload);
    }
  };

  const onSupplyStatusChange = (value: ESupplyStatus) => {
    changeGroupedSupplyListStatus({
      ids: selectedSupplyListIds,
      status: value,
      onSuccess: () => onChangeStatusSuccess(selectedSupplyListIds),
    });
  };

  return (
    <>
      {!!list.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать партии
          </Checkbox>

          {!!selectedSupplyList.length && (
            <>
              <Button className="button-s primary" onClick={() => onSupplyStatusChange(ESupplyStatus.Approved)}>
                Согласовать доставку
              </Button>

              <Button className="button-s danger" onClick={() => onSupplyStatusChange(ESupplyStatus.Cancelled)}>
                Отменить выбранные
              </Button>
            </>
          )}
        </div>
      )}

      <Spin wrapperClassName="need-list__spin" spinning={loading} indicator={<SpinIndicator />}>
        {groupedSupplyList.map((group, index) => {
          return (
            <div key={index} className="need-list__group">
              <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.deliveryDate)}</span>

              <Table
                className="need-list__table need-list__table-expandable"
                rowClassName="need-list__table-expandable-row"
                columns={renderGroupedSupplyListRecords(selectedSupplyListIds, onGroupSelectionChange)}
                dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
                expandable={{
                  expandIconColumnIndex: 7,
                  columnWidth: 40,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return (
                      <Button
                        className="button-icon need-list__table-item-expand-btn"
                        icon={expanded ? <ArrowUpShortIcon /> : <ArrowDownShortIcon />}
                        onClick={(e) => onExpand(record, e)}
                      />
                    );
                  },
                  expandedRowRender: ({ dataSource }) => {
                    return (
                      <Table
                        className="table-hover need-list__table"
                        columns={renderSupplyListRecords(ESupplyListMenuTab.Pending)}
                        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                        pagination={false}
                        showHeader={false}
                        rowSelection={{
                          selectedRowKeys: selectedSupplyListIds,
                          onSelect: (record, checked) => onSupplySelectionChange(record, checked),
                          columnWidth: 40,
                          getCheckboxProps: ({ address }) => ({ disabled: !address }),
                        }}
                        onRow={({ id, supplyNeedId }) => ({
                          onClick: async () => {
                            await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                            await getNeedById({ id: supplyNeedId });
                          },
                        })}
                      />
                    );
                  },
                }}
                pagination={false}
                showHeader={false}
              />
            </div>
          );
        })}

        <SupplyListEmpty open={!loading && !list.length} forSeller />
      </Spin>

      <SupplyCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <NeedCard nested />

      <SupplyCorrectionRequestDrawer onChangeStatusSuccess={onChangeStatusSuccess} />

      <GoodsCard />
    </>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  filterGroupedSupplyList: dispatch.groupedSupplyListState.filterGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  changeGroupedSupplyListStatus: dispatch.groupedSupplyListState.changeGroupedSupplyListStatus,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingSupplyListForManager = connect(null, mapDispatch)(Component);
