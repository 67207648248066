import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ENeedCardMenuItem, ENeedStatus, ERequestsMenuTab } from 'common/const/enum';
import { COLLECTION_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch } from 'app/store';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { renderNeedListRecords } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';
import { GoodsCard } from 'entities/Goods/components/GoodsCard-r';

interface IComponentProps {
  list: INeed[];
  loading: boolean;
  count: number;
  payload: INeedListPayload;
  onPayloadChange: (value: INeedListPayload) => void;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const Component: React.FC<AllType> = (props) => {
  const {
    // props
    list,
    loading,
    count,
    payload,
    onPayloadChange,
    // dispatch
    filterNeedList,
    getNeedList,
    changeNeedStatus,
    getNeedById,
  } = props;

  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const {
    setPage,
    setNeedCardCurrentMenuTab,
    setOpenNeedCard,
    checkAll,
    indeterminate,
    selectedNeedListIds,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedNeedList(list);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = list.find((item) => item.id === id) as INeed;

    setSelectedNeedList(checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id));
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredNeedList = list.filter((need) => !ids.includes(need.id));
    const filteredSelectedNeedList = selectedNeedList.filter((need) => !ids.includes(need.id));

    filterNeedList(ids);
    setSelectedNeedList(filteredSelectedNeedList);

    if (filteredNeedList.length <= COLLECTION_LIMIT_5 && count > filteredNeedList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      onPayloadChange(newPayload);
      getNeedList(newPayload);
    }
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        setOpenWarningDrawer(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) завершены.`);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  return (
    <>
      {!!list.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать заявки
          </Checkbox>

          {!!selectedNeedList.length && (
            <Button className="button-s danger" onClick={() => setOpenWarningDrawer(true)}>
              Завершить выбранные
            </Button>
          )}
        </div>
      )}

      <Table
        className={`
            table-hover need-list__table
            ${!!list.length && list.length === count && 'need-list__table-end-of-list'}
            ${!list.length && 'need-list__table-empty'}
          `}
        dataSource={list.map((need) => ({ ...need, key: need.id }))}
        columns={renderNeedListRecords(ERequestsMenuTab.Pending)}
        rowSelection={{
          selectedRowKeys: selectedNeedListIds,
          onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
          columnWidth: 40,
        }}
        onRow={({ id }) => ({
          onClick: () => {
            getNeedById({
              id,
              onSuccess: () => {
                setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                setOpenNeedCard(true);
              },
            });
          },
        })}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: <NeedListEmpty open={!loading && !list.length} /> }}
        loading={{ spinning: loading, indicator: <SpinIndicator /> }}
      />

      <NeedCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <SupplyCard nested onChangeStatusSuccess={onChangeStatusSuccess} />

      <SupplyCorrectionRequestDrawer />

      <GoodsCard />

      <WarningDrawer
        open={openWarningDrawer}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Запланированные поставки по данным заявкам будут отменены."
        confirmBtnTitle="Завершить все"
        loading={loading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  filterNeedList: dispatch.needListState.filterNeedList,
  getNeedList: dispatch.needListState.getNeedList,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingNeedListForManager = connect(null, mapDispatch)(Component);
