import React, { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EScrollDirection } from 'common/const/enum';

interface IComponentProps {
  loading?: boolean;
  canLoad?: boolean;
  page: number;
  scrollToTopTrigger?: any[];
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
  onPageChange: (value: number) => void;
  setScrollDirection?: (value: EScrollDirection) => void;
  callback?: (page: number) => Promise<void>;
}

export const InfiniteScrollContainer: FC<IComponentProps> = (props) => {
  const {
    loading = false,
    canLoad,
    page,
    scrollToTopTrigger = [],
    className,
    containerClassName,
    children,
    onPageChange,
    setScrollDirection,
    callback,
  } = props;

  const scrollRef = useRef<HTMLDivElement>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  let previousScrollTop = 0;

  const handleScroll = () => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollTop > previousScrollTop) {
      setScrollDirection?.(EScrollDirection.DOWN);
    } else if (scrollTop < previousScrollTop) {
      setScrollDirection?.(EScrollDirection.UP);
    }

    previousScrollTop = scrollTop;

    if (scrollHeight - scrollTop <= clientHeight + 100 && canLoad) {
      setFetching(true);
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [canLoad]);

  useEffect(() => {
    const fetchData = async () => {
      await callback?.(page);
      onPageChange(page + 1);
      setFetching(false);
    };

    if (fetching) {
      fetchData();
    }
  }, [fetching]);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0 });
    previousScrollTop = 0;
  }, [...scrollToTopTrigger]);

  return (
    <Spin wrapperClassName="infinite-scroll-container__spin" spinning={loading} indicator={<SpinIndicator />}>
      <div className={`infinite-scroll-container ${className}`}>
        <div className={`infinite-scroll-container__scroll-container ${containerClassName}`} ref={scrollRef}>
          {children}
        </div>
      </div>
    </Spin>
  );
};
