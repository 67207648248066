import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EGroupedSupplyListKey, ESearchParams, ESupplyListMenuTab } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, DEFAULT_LIST_LIMIT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';
import { CreatedSupplyListForSeller } from 'entities/Supply/components/CreatedSupplyListForSeller';
import { ProcessingSupplyListForSeller } from 'entities/Supply/components/ProcessingSupplyListForSeller';
import { PendingSupplyListForSeller } from 'entities/Supply/components/PendingSupplyListForSeller';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { getSupplyListForSellerStatuses } from 'entities/Supply/Supply.helper';
import { incomingSupplyListForSellerMenuTabs } from 'entities/Supply/Supply.const';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    buyerList,
    subdivisionList,
    addressList,
    userList,
    supplyListLoading,
    groupedSupplyList,
    groupedSupplyListCount,
    groupedSupplyListLoading,
    // dispatch
    getBuyerList,
    getSupplySubdivisionListForSeller,
    getSupplyAddressListForSeller,
    getSupplyUserListForSeller,
    setGroupedSupplyList,
    getGroupedSupplyList,
    getGroupedSupplyListPart,
  } = props;

  const { page, setPage, fetchLoading, setFetchLoading, setSelectedSupplyList } = useSupplyContext();
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const buyerOptions = getNeedListFilterOptions.buyer(buyerList);
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const addressOptions = getNeedListFilterOptions.address(addressList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);

  const [payload, setPayload] = useState<IGroupedSupplyListPayload>({
    statuses: getSupplyListForSellerStatuses(tab),
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
    key: !tab || tab === ESupplyListMenuTab.Created ? EGroupedSupplyListKey.ByUpdatedAt : EGroupedSupplyListKey.ByDeliveryDate,
  });

  const onCurrentMenuTabChange = (key: ESupplyListMenuTab) => {
    const newPayload = {
      ...payload,
      accountId: undefined,
      subdivisionIds: undefined,
      addressId: undefined,
      userId: undefined,
      statuses: getSupplyListForSellerStatuses(key),
      key: key === ESupplyListMenuTab.Created ? EGroupedSupplyListKey.ByUpdatedAt : EGroupedSupplyListKey.ByDeliveryDate,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    setSearchParam(ESearchParams.Tab, key);
    setPayload(newPayload);
    getGroupedSupplyList(newPayload);
  };

  const renderContent = () => {
    switch (tab) {
      case ESupplyListMenuTab.Processing: {
        return (
          <ProcessingSupplyListForSeller
            list={groupedSupplyList}
            count={groupedSupplyListCount}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
            payload={payload}
            onPayloadChange={setPayload}
          />
        );
      }
      case ESupplyListMenuTab.Pending: {
        return (
          <PendingSupplyListForSeller
            list={groupedSupplyList}
            count={groupedSupplyListCount}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
            payload={payload}
            onPayloadChange={setPayload}
          />
        );
      }
      default: {
        return (
          <CreatedSupplyListForSeller
            list={groupedSupplyList}
            count={groupedSupplyListCount}
            loading={groupedSupplyListLoading || supplyListLoading || fetchLoading}
            payload={payload}
            onPayloadChange={setPayload}
          />
        );
      }
    }
  };

  const onBuyerChange = async (value: number) => {
    const newPayload = {
      ...payload,
      accountId: value === DEFAULT_EMPTY_VALUE ? undefined : value,
      subdivisionIds: undefined,
      addressId: undefined,
      userId: undefined,
    };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);

    if (value !== DEFAULT_EMPTY_VALUE) {
      await getSupplySubdivisionListForSeller({ statuses: newPayload.statuses, buyerId: value });
      await getSupplyAddressListForSeller({ statuses: newPayload.statuses, buyerId: value });
      await getSupplyUserListForSeller({ statuses: newPayload.statuses, buyerId: value });
    }

    await getGroupedSupplyList(newPayload);
  };

  const onSubdivisionChange = async (value: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value],
      addressId: undefined,
      userId: undefined,
    };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    await getGroupedSupplyList(newPayload);
  };

  const onAddressChange = (value: number) => {
    const newPayload = { ...payload, addressId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    getGroupedSupplyList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setGroupedSupplyList([]);
    getGroupedSupplyList(newPayload);
  };

  const onSearchClick = () => {
    if (payload) {
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      setGroupedSupplyList([]);
      getGroupedSupplyList(payload);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      setGroupedSupplyList([]);
      await getBuyerList();
      await getGroupedSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <InfiniteScrollContainer
      page={page}
      scrollToTopTrigger={[payload]}
      canLoad={!groupedSupplyListLoading && groupedSupplyList.length < groupedSupplyListCount}
      onPageChange={setPage}
      callback={async (page) => {
        await getGroupedSupplyListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
      }}
    >
      {header}

      {navigation}

      <Menu
        items={incomingSupplyListForSellerMenuTabs}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Created : tab]}
        onClick={({ key }) => onCurrentMenuTabChange(key as ESupplyListMenuTab)}
        mode="horizontal"
      />

      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={buyerOptions}
          value={payload?.accountId ? payload.accountId : DEFAULT_EMPTY_VALUE}
          onChange={onBuyerChange}
          placeholder="Выберите компанию"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownShortIcon />}
        />

        {payload.accountId && (
          <>
            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={subdivisionOptions}
              value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
              onChange={onSubdivisionChange}
              placeholder="Выберите подразделение"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={addressOptions}
              value={payload.addressId ? payload.addressId : DEFAULT_EMPTY_VALUE}
              onChange={onAddressChange}
              placeholder="Выберите адрес отгрузки"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={userOptions}
              value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
              onChange={onUserChange}
              placeholder="Выберите заявителя"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />
          </>
        )}

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload?.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
      </div>

      {renderContent()}
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  buyerList: state.buyerListState.data,
  subdivisionList: state.supplyListState.subdivisionList,
  addressList: state.supplyListState.addressList,
  userList: state.supplyListState.userList,
  supplyListLoading: state.supplyListState.loading,
  groupedSupplyList: state.groupedSupplyListState.data,
  groupedSupplyListCount: state.groupedSupplyListState.count,
  groupedSupplyListLoading: state.groupedSupplyListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBuyerList: dispatch.buyerListState.getBuyerList,
  getSupplySubdivisionListForSeller: dispatch.supplyListState.getSupplySubdivisionListForSeller,
  getSupplyAddressListForSeller: dispatch.supplyListState.getSupplyAddressListForSeller,
  getSupplyUserListForSeller: dispatch.supplyListState.getSupplyUserListForSeller,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  getGroupedSupplyListPart: dispatch.groupedSupplyListState.getGroupedSupplyListPart,
});

export const IncomingRequestsForSeller = connect(mapState, mapDispatch)(Component);
